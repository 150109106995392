import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "../../services/CategoryService";
import { getBrands } from "../../services/BrandService";
import { addCategoryMapBrand, getCategoryMapBrand } from "../../services/CategoryMapBrandService";
import MapCategoryVsBrand from "../../components/map-cate-vs-brand/MapCategoryVsBrand";
import SnackbarAlert from "../../components/snackbarAlert/SnackbarAlert";
import { Backdrop, Box, CircularProgress } from "@mui/material";

function CategoryMapBrand() {
  const dispatch = useDispatch();
  const brands = useSelector((state) => {
    return state.brand.brand;
  });
  const categories = useSelector((state) => {
    return state.category.category;
  });
  const [listCategory, setListCategory] = useState([]);
  const [listBrand, setListBrand] = useState([]);
  const [listCateMapBrand, setListCateMapBrand] = useState();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [textSnackbar, setTextSnackbar] = useState({ text: "Đang xử lý !", severity: "success" });
  const [backdrop, setBackdrop] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const resCate = await getCategories(dispatch);
      setListCategory(resCate);
      const resBrand = await getBrands(dispatch);
      setListBrand(resBrand);
      const resCateMapBrand = await getCategoryMapBrand();
      setListCateMapBrand(resCateMapBrand);
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  const handSubmit = async (data) => {
    setBackdrop(true);
    const res = await addCategoryMapBrand(data.categoryId, data.brandIds);
    setTextSnackbar({ text: res.data?.message, severity: "success" });
    setOpenSnackbar(true);
    setBackdrop(false);
    if (res.data?.success === true) {
      return true;
    }
  };

  const getBrandIdsForCategory = (categoryId, listCateMapBrand) => {
    if (!listCateMapBrand || listCateMapBrand.length === 0) {
      return [];
    }
    const categoryMap = listCateMapBrand.find((item) => item.categoryId === categoryId);
    if (categoryMap) {
      return categoryMap.brandIds;
    }

    return [];
  };

  return (
    <div>
      <h2>Liên kết Thể Loại với Thương Hiệu</h2>
      {brands.isFetching || categories.isFetching ? (
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      ) : (
        listCategory.map((item) => {
          return (
            <MapCategoryVsBrand
              key={item._id}
              cate={item}
              listBrand={listBrand}
              wasChoose={getBrandIdsForCategory(item._id, listCateMapBrand)}
              onSave={handSubmit}
            />
          );
        })
      )}
      <SnackbarAlert
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        severity={textSnackbar.severity}
        text={textSnackbar.text}
      />
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default CategoryMapBrand;
