import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

function Searchbar({ onSearch, className }) {
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      onSearch(searchText);
    }, 1000);

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [searchText, onSearch]);

  const handleSearch = (event) => {
    const searchText = event.target.value;
    setSearchText(searchText);
  };
  return (
    <TextField
      className={className}
      variant="outlined"
      size="small"
      placeholder="Tìm kiếm..."
      onChange={handleSearch}
      InputProps={{
        startAdornment: <SearchIcon />,
      }}
    />
  );
}

export default Searchbar;
