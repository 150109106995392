import { Autocomplete, Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./MapCategoryVsBrand.css";

function MapCategoryVsBrand({ cate, listBrand, wasChoose, onSave }) {
  const [choose, setChoose] = useState([]);
  const [isChange, setIsChange] = useState(false);

  // Lọc danh sách brand theo wasChoose
  const filteredListBrand = (listBrand, wasChoose) => {
    return listBrand.filter(
      (brand) => wasChoose && wasChoose.length > 0 && wasChoose.includes(brand._id),
    );
  };
  useEffect(() => {
    setChoose(wasChoose);
  }, [wasChoose]);

  const handSubmit = async () => {
    const result = await onSave({ categoryId: cate._id, brandIds: choose });
    if (result === true) {
      setIsChange(false);
    }
  };
  const handOnChange = (event, newValue) => {
    const newChoose = newValue.map((brand) => brand._id);
    setChoose(newChoose);
    setIsChange(true);
  };
  return (
    <div className="mb-3">
      <div className="map_title">{cate.cateName}:</div>
      <div className="d-flex justify-content-between align-item-baseline mt-2">
        <Autocomplete
          value={filteredListBrand(listBrand, choose)}
          multiple
          options={listBrand}
          onChange={handOnChange}
          getOptionLabel={(option) => option.brandName}
          filterSelectedOptions
          sx={{ width: "80%" }}
          renderInput={(params) => <TextField {...params} label="Chọn Thương hiệu" />}
        />
        <Button variant="contained" color="success" onClick={handSubmit} disabled={!isChange}>
          Lưu lại
        </Button>
      </div>
    </div>
  );
}

export default MapCategoryVsBrand;
