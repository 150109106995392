import axios from "axios";
import ApiUrl from "../utils/RewriteApi";
import { clearUser, setAssetToken, setUserInfo } from "../redux/slice/UserSlice";
import setAuthToken from "../utils/setAuthToken";

export const loginService = async (dispath, user, pass) => {
  try {
    const res = await axios.post(ApiUrl("/auth/login-admin"), { username: user, password: pass });
    if (res.data.success) {
      dispath(setAssetToken(res.data.accessToken));
      setAuthToken(res.data.accessToken);
      return res.data;
    }
  } catch (error) {
    dispath(clearUser());
    return error;
  }
};

export const loadUser = async (dispath) => {
  try {
    const res = await axios.get(ApiUrl("/auth/loaduser"));
    if (res.data.success) {
      dispath(setUserInfo(res.data.user));
    }
    return res.data;
  } catch (error) {
    dispath(clearUser());
    console.log(error);
    setAuthToken();
  }
};

export const logoutService = (dispath) => {
  dispath(clearUser());
  setAuthToken();
};
