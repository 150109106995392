import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Stack,
} from "@mui/material";
import slugify from "slugify";
import { uploadImageToImgur } from "../../services/UploadImageToImgur";

const CategoryDetail = ({ item, open, onClose, onSave }) => {
  const [categoryData, setCategoryData] = useState({
    cateName: "",
    cateSlug: "",
    description: "",
    image: "",
  });
  const [errors, setErrors] = useState({
    cateName: "",
    cateSlug: "",
    description: "",
    image: "",
  });

  useEffect(() => {
    if (item) {
      setCategoryData(item);
    } else {
      setCategoryData({
        cateName: "",
        cateSlug: "",
        description: "",
        image: "",
      });
    }
    return () => {
      setCategoryData({
        cateName: "",
        cateSlug: "",
        description: "",
        image: "",
      });
    };
  }, [item]);

  const handleChange = (event) => {
    const { name, value, files } = event.target;
    if (name === "image" && files && files[0]) {
      // Lưu ảnh tạm thời vào localStorage dưới dạng Base64
      const reader = new FileReader();
      reader.onloadend = () => {
        setCategoryData((prevData) => ({
          ...prevData,
          image: reader.result,
        }));
      };
      reader.readAsDataURL(files[0]);

      // Xoá lỗi của trường ảnh khi có file ảnh được chọn
      setErrors((prevErrors) => ({
        ...prevErrors,
        image: "",
      }));
    } else {
      setCategoryData((prevData) => ({
        ...prevData,
        [name]: value,
      }));

      // Xoá lỗi của trường Đường dẫn khi người dùng nhập
      if (name === "cateSlug" || name === "cateName") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
      }
    }
  };

  const handleSave = async () => {
    if (!validateForm()) {
      return;
    }
    if (categoryData.image !== item?.image) {
      // Nếu có thay đổi ảnh mới, thực hiện upload lên imgur
      const imageUrl = await uploadImageToImgur(categoryData.image);

      onSave({
        ...categoryData,
        image: imageUrl,
      });
    } else {
      // Nếu không có thay đổi ảnh, giữ nguyên link ảnh hiện tại
      onSave(categoryData);
    }
    onClose();
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      cateName: "",
      cateSlug: "",
      description: "",
      image: "",
    };

    if (!categoryData.cateName) {
      newErrors.cateName = "Vui lòng nhập Tên Thể Loại";
      isValid = false;
    }

    if (!categoryData.cateSlug) {
      newErrors.cateSlug = "Vui lòng nhập Slug Thể Loại";
      isValid = false;
    }

    if (!categoryData.description) {
      newErrors.description = "Vui lòng nhập Mô tả Thể Loại";
      isValid = false;
    }

    if (!categoryData.image) {
      newErrors.image = "Vui lòng chọn ảnh Thể Loại";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{item ? "Sửa Thể Loại" : "Thêm Thể Loại"}</DialogTitle>
      <DialogContent>
        <TextField
          label="Tên Thể Loại*"
          name="cateName"
          value={categoryData.cateName}
          onChange={handleChange}
          fullWidth
          margin="normal"
          error={Boolean(errors.cateName)}
          helperText={errors.cateName}
        />
        <Stack direction="row" spacing={2}>
          <TextField
            label="Slug*"
            name="cateSlug"
            value={categoryData.cateSlug}
            onChange={handleChange}
            fullWidth
            margin="normal"
            error={Boolean(errors.cateSlug)}
            helperText={errors.cateSlug}
          />
          <Button
            variant="contained"
            onClick={() =>
              setCategoryData((prevData) => ({
                ...prevData,
                cateSlug: slugify(categoryData.cateName, { lower: true, remove: /[*+~.()'"!:@]/g }),
              }))
            }
          >
            Tạo
          </Button>
        </Stack>
        <TextField
          label="Mô tả*"
          name="description"
          value={categoryData.description}
          onChange={handleChange}
          fullWidth
          margin="normal"
          error={Boolean(errors.description)}
          helperText={errors.description}
        />
        <input type="file" name="image" accept="image/*" onChange={handleChange} />
        {errors.image && <div style={{ color: "red" }}>{errors.image}</div>}
        {categoryData.image && (
          <img src={categoryData.image} alt="Preview" style={{ maxWidth: "100%" }} />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Hủy</Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          Lưu
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CategoryDetail;
