import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Checkbox,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import { useSelector, useDispatch } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  createCategory,
  deleteCategory,
  deleteMultiCategory,
  getCategories,
  updateCategory,
} from "../../services/CategoryService";
import "./Category.css";
import SnackbarAlert from "../../components/snackbarAlert/SnackbarAlert";
import Searchbar from "../../components/searchbar/Searchbar";
import CategoryDetail from "./CategoryDetail";
import IsDeleteDialog from "../../components/isDeleteDialog/IsDeleteDialog";
import Box from "../../components/box/Box";

function Category() {
  const categories = useSelector((state) => {
    return state.category.category;
  });
  const dispatch = useDispatch();
  const [listCategory, setListCategory] = useState([]);
  const [backdrop, setBackdrop] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [textSnackbar, setTextSnackbar] = useState({ text: "Đang xử lý !", severity: "success" });
  const [searchText, setSearchText] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  useEffect(() => {
    const fetchCategory = async () => {
      const res = await getCategories(dispatch, searchText);
      setListCategory(res);
    };
    fetchCategory();
    // eslint-disable-next-line
  }, [searchText]);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedItems(listCategory.map((item) => item._id));
    } else {
      setSelectedItems([]);
    }
    setIsAllSelected(event.target.checked);
  };
  const handleSelectItem = (event, id) => {
    if (event.target.checked) {
      setSelectedItems((prev) => [...prev, id]);
    } else {
      setSelectedItems((prev) => prev.filter((itemId) => itemId !== id));
    }
  };
  const handeDeleteCategory = async (id) => {
    setBackdrop(true);
    const res = await deleteCategory(id);
    setTextSnackbar({ text: res.data.message, severity: "success" });
    const updatedListCategory = listCategory.filter((category) => category._id !== id);
    setListCategory(updatedListCategory);
    setOpenSnackbar(true);
    setBackdrop(false);
  };
  const handleDeleteMultiCategory = async () => {
    setBackdrop(true);
    const res = await deleteMultiCategory(selectedItems);
    setTextSnackbar({ text: res.data?.message, severity: "success" });
    const updatedListCategory = listCategory.filter(
      (category) => !selectedItems.includes(category._id),
    );
    setListCategory(updatedListCategory);
    setSelectedItems([]);
    setOpenSnackbar(true);
    setBackdrop(false);
    handleCloseDeleteDialog();
  };
  const handleOpenCategoryDetail = (item) => {
    setSelectedItem(item);
    setOpenDialog(true);
  };

  const handleCloseCategoryDetail = () => {
    setOpenDialog(false);
    setSelectedItem(null);
  };

  const handleSaveCategory = async (data) => {
    setBackdrop(true);
    let res = { data: { message: "Đã xảy ra lỗi khi lưu thể loại!" } };
    try {
      if (data._id) {
        res = await updateCategory(data);
        setTextSnackbar({ text: res.data.message, severity: "success" });
        const updatedListCategory =
          listCategory.length === 0
            ? [data]
            : listCategory.map((category) => {
                if (category._id === data._id) {
                  return data;
                }
                return category;
              });
        setListCategory(updatedListCategory);
      } else {
        res = await createCategory(data);
        setTextSnackbar({ text: res.data.message, severity: "success" });
        setListCategory([...listCategory, res.data.category]);
      }
    } catch (error) {
      console.error(error);
      setTextSnackbar({
        text: res.data.message,
        severity: "error",
      });
    }
    setOpenSnackbar(true);
    setBackdrop(false);
  };

  const handleSearch = (text) => {
    setSearchText(text);
  };

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  return (
    <Box>
      <div className="head_container d-flex justify-content-between m-0">
        <h2 className="title_container">Danh Sách Thể Loại</h2>
        <div className="control_container d-flex justify-content-end">
          {selectedItems.length > 0 ? (
            <div className="delete_button">
              <Button
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={handleOpenDeleteDialog}
              >
                Xoá nhiều
              </Button>
            </div>
          ) : (
            ""
          )}
          <div className="add_button">
            <Button
              variant="outlined"
              color="success"
              startIcon={<CreateIcon />}
              onClick={() => handleOpenCategoryDetail(null)}
            >
              Tạo mới
            </Button>
          </div>
          <Searchbar onSearch={handleSearch} />
        </div>
      </div>
      {categories.isFetching ? (
        <CircularProgress />
      ) : (
        <>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={backdrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <TableContainer className="custom-table">
            <Table className="category-table">
              <TableHead>
                <TableRow>
                  <TableCell className="checkbox-cell">
                    <Checkbox
                      checked={isAllSelected || selectedItems.length === listCategory.length}
                      onChange={handleSelectAll}
                      indeterminate={
                        selectedItems.length > 0 && selectedItems.length < listCategory.length
                      }
                    />
                  </TableCell>
                  <TableCell className="image-cell">Ảnh</TableCell>
                  <TableCell className="cateName-cell">Tên danh mục</TableCell>
                  <TableCell className="cateSlug-cell">Slug</TableCell>
                  <TableCell className="description-cell">Mô tả</TableCell>
                  <TableCell className="delete-cell">Xoá</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listCategory?.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell className="checkbox-cell">
                        <Checkbox
                          checked={selectedItems.includes(item._id)}
                          onChange={(event) => handleSelectItem(event, item._id)}
                        />
                      </TableCell>
                      <TableCell
                        className="image-cell"
                        onClick={() => handleOpenCategoryDetail(item)}
                      >
                        <LazyLoadImage
                          src={item.image}
                          effect="blur"
                          alt=""
                          height="100"
                          placeholderSrc={item.image}
                        />
                      </TableCell>
                      <TableCell
                        className="cateName-cell"
                        onClick={() => handleOpenCategoryDetail(item)}
                      >
                        {item.cateName}
                      </TableCell>
                      <TableCell
                        className="cateSlug-cell"
                        onClick={() => handleOpenCategoryDetail(item)}
                      >
                        {item.cateSlug}
                      </TableCell>
                      <TableCell
                        className="description-cell"
                        onClick={() => handleOpenCategoryDetail(item)}
                      >
                        {item.description}
                      </TableCell>
                      <TableCell className="delete-cell">
                        <IconButton
                          aria-label="delete"
                          color="error"
                          onClick={() => handeDeleteCategory(item._id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      <SnackbarAlert
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        severity={textSnackbar.severity}
        text={textSnackbar.text}
      />
      <CategoryDetail
        item={selectedItem}
        open={openDialog}
        onClose={handleCloseCategoryDetail}
        onSave={handleSaveCategory}
      />
      <IsDeleteDialog
        title={`Bạn có chắc chắn muốn xoá ${selectedItems.length} thể loại không?`}
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleDeleteMultiCategory}
      />
    </Box>
  );
}

export default Category;
