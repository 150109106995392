import axios from "axios";
import ApiUrl from "../utils/RewriteApi";

export const getProduct = async ({ cateId, brandIds, searchText, itemsPerPage, page }) => {
  const params = {
    category_id: cateId,
    brand_id: brandIds,
    searchText: searchText,
    itemsPerPage: itemsPerPage,
    page: page,
  };

  try {
    const res = await axios.get(ApiUrl("/product/get-all-products"), { params });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteMultiProduct = async (productIds) => {
  try {
    const res = await axios.delete(ApiUrl("/product/delete-product"), {
      data: { productIds }, // Gửi mảng các ID sản phẩm cần xóa trong body request
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const createProduct = async (product) => {
  try {
    const res = await axios.post(ApiUrl(`/product/create-product`), {
      productName: product.productName,
      productSlug: product.productSlug,
      description: product.description,
      tag: product.tag,
      options: product.options,
      category_id: product.category_id,
      brand_id: product.brand_id,
      image: product.image,
      currentPrice: product.currentPrice,
      originalPrice: product.originalPrice,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const updateProduct = async (product) => {
  try {
    const res = await axios.put(ApiUrl(`/product/update-product`), {
      _id: product._id,
      productName: product.productName,
      productSlug: product.productSlug,
      description: product.description,
      tag: product.tag,
      options: product.options,
      category_id: product.category_id,
      brand_id: product.brand_id,
      image: product.image,
      currentPrice: product.currentPrice,
      originalPrice: product.originalPrice,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};
