import { uploadImageToImgur } from "../../services/UploadImageToImgur";

class ImgurUploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }

  async upload() {
    try {
      const imageUrl = await uploadImageToImgur(this.loader._reader._data);
      return { default: imageUrl };
    } catch (error) {
      console.error("Lỗi khi tải ảnh lên Imgur:", error);
    }
  }

  abort() {
    // Không cần thực hiện gì ở đây
  }
}

function ImgurUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return new ImgurUploadAdapter(loader);
  };
}

export default ImgurUploadAdapterPlugin;
