const sidebarNav = [
  {
    link: "/",
    section: "home",
    icon: <i className="bx bx-home-alt"></i>,
    text: "Trang chủ",
  },
  {
    link: "/orders",
    section: "orders",
    icon: <i className="bx bx-receipt"></i>,
    text: "Đơn hàng",
  },
  {
    link: "/products",
    section: "products",
    icon: <i className="bx bx-cube"></i>,
    text: "Sản phẩm",
  },
  {
    link: "/categories",
    section: "categories",
    icon: <i className="bx bx-category"></i>,
    text: "Danh mục",
  },
  {
    link: "/sales",
    section: "sales",
    icon: <i className="bx bx-bulb"></i>,
    text: "Sự kiện",
  },
  {
    link: "/customers",
    section: "customers",
    icon: <i className="bx bx-user"></i>,
    text: "Khách hàng",
  },
  {
    link: "/staff",
    section: "staff",
    icon: <i className="bx bx-line-chart"></i>,
    text: "Nhân viên",
  },
  {
    link: "/settings",
    section: "settings",
    icon: <i className="bx bx-cog"></i>,
    text: "Cài đặt",
  },
];

export default sidebarNav;
