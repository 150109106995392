import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { uploadImageToImgur } from "../../services/UploadImageToImgur";

const BannerDetail = ({ item, open, onClose, onSave }) => {
  const [bannerData, setBannerData] = useState({
    description: "",
    linkTo: "",
    isActive: false,
    image: "",
  });
  const [errors, setErrors] = useState({
    linkTo: "",
    image: "",
  });

  useEffect(() => {
    if (item) {
      setBannerData(item);
    } else {
      setBannerData({
        description: "",
        linkTo: "",
        isActive: false,
        image: "",
      });
    }
  }, [item]);

  const handleChange = (event) => {
    const { name, value, checked, files } = event.target;
    if (name === "image" && files && files[0]) {
      // Lưu ảnh tạm thời vào localStorage dưới dạng Base64
      const reader = new FileReader();
      reader.onloadend = () => {
        setBannerData((prevData) => ({
          ...prevData,
          image: reader.result,
        }));
      };
      reader.readAsDataURL(files[0]);

      // Xoá lỗi của trường ảnh khi có file ảnh được chọn
      setErrors((prevErrors) => ({
        ...prevErrors,
        image: "",
      }));
    } else {
      const inputValue = name === "isActive" ? checked : value;
      setBannerData((prevData) => ({
        ...prevData,
        [name]: inputValue,
      }));

      // Xoá lỗi của trường Đường dẫn khi người dùng nhập
      if (name === "linkTo") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          linkTo: "",
        }));
      }
    }
  };

  const handleSave = async () => {
    if (!validateForm()) {
      return;
    }
    if (bannerData.image !== item?.image) {
      // Nếu có thay đổi ảnh mới, thực hiện upload lên imgur
      const imageUrl = await uploadImageToImgur(bannerData.image);

      onSave({
        ...bannerData,
        image: imageUrl,
      });
    } else {
      // Nếu không có thay đổi ảnh, giữ nguyên link ảnh hiện tại
      onSave(bannerData);
    }
    onClose();
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      linkTo: "",
      image: "",
    };

    if (!bannerData.linkTo) {
      newErrors.linkTo = "Vui lòng nhập Đường dẫn";
      isValid = false;
    }

    if (!bannerData.image) {
      newErrors.image = "Vui lòng chọn ảnh";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{item ? "Sửa Banner" : "Thêm Banner"}</DialogTitle>
      <DialogContent>
        <TextField
          label="Mô tả"
          name="description"
          value={bannerData.description}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Đường dẫn*"
          name="linkTo"
          value={bannerData.linkTo}
          onChange={handleChange}
          fullWidth
          margin="normal"
          error={Boolean(errors.linkTo)}
          helperText={errors.linkTo}
        />
        <FormControlLabel
          control={
            <Checkbox name="isActive" checked={bannerData.isActive} onChange={handleChange} />
          }
          label="Kích hoạt ngay"
        />
        <input type="file" name="image" accept="image/*" onChange={handleChange} />
        {errors.image && <div style={{ color: "red" }}>{errors.image}</div>}
        {bannerData.image && (
          <img src={bannerData.image} alt="Preview" style={{ maxWidth: "100%" }} />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Hủy</Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          Lưu
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BannerDetail;
