import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Blank from "./pages/Blank";
import Dashboard from "./pages/dashboard/Dashboard";
import MainLayout from "./layout/mainLayout/MainLayout";
import Sales from "./pages/sales/Sales";
import AuthPage from "./pages/auth/AuthPage";
import Categories from "./pages/categories/Categories";
import ProductScreen from "./pages/products/ProductScreen";
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "./services/AuthService";

function AppRouter() {
  const dispatch = useDispatch();
  const user = useSelector((state) => {
    return state.user;
  });
  useEffect(() => {
    if (user.assetToken) {
      loadUser(dispatch);
    }
    // eslint-disable-next-line
  }, [user.assetToken]);

  return (
    <div>
      {user.userInfo ? (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<MainLayout />}>
              <Route index element={<Dashboard />} />
              <Route path="categories" element={<Categories />} />
              <Route path="sales" element={<Sales />} />
              <Route path="products" element={<ProductScreen />} />
              <Route path="orders" element={<Blank />} />
              <Route path="customers" element={<Blank />} />
              <Route path="settings" element={<Blank />} />
              <Route path="staff" element={<Blank />} />
              <Route path="*" element={<Blank />} />
            </Route>
          </Routes>
        </BrowserRouter>
      ) : (
        <AuthPage />
      )}
    </div>
  );
}

export default AppRouter;
