import axios from "axios";
import ApiUrl from "../utils/RewriteApi";
import {
  getCategoryStart,
  getCategorySuccess,
  getCategoryFailed,
} from "../redux/slice/CategorySlice";

export const getCategories = async (dispatch, searchText) => {
  dispatch(getCategoryStart());
  try {
    const res = await axios.get(ApiUrl(`/category/get-category`), {
      params: { searchText },
    });
    dispatch(getCategorySuccess(res.data.listCategory));
    return res.data.listCategory;
  } catch (error) {
    dispatch(getCategoryFailed());
    console.log(error);
  }
};

export const createCategory = async (category) => {
  try {
    const res = await axios.post(ApiUrl(`/category/add-category`), {
      cateName: category.cateName,
      cateSlug: category.cateSlug,
      description: category.description,
      image:
        category.image ||
        "https://images.pexels.com/photos/335257/pexels-photo-335257.jpeg?cs=srgb&dl=pexels-eprism-studio-335257.jpg&fm=jpg",
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const updateCategory = async (category) => {
  try {
    const res = await axios.put(ApiUrl(`/category/update-category`), {
      _id: category._id,
      cateName: category.cateName,
      cateSlug: category.cateSlug,
      description: category.description,
      image: category.image,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const deleteCategory = async (categoryId) => {
  try {
    const res = await axios.delete(ApiUrl(`/category/delete-category`), {
      data: { _id: categoryId },
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const deleteMultiCategory = async (categoryIds) => {
  try {
    const res = await axios.delete(ApiUrl(`/category/delete-multi-category`), {
      data: { categoryIds },
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};
