import axios from "axios";
import ApiUrl from "../utils/RewriteApi";
import { getBannerStart, getBannerSuccess, getBannerFailed } from "../redux/slice/BannerSlice";

export const getBanner = async (dispath, isAll, searchText) => {
  dispath(getBannerStart());
  try {
    const res = await axios.get(ApiUrl(`/banner/${isAll ? "get-all-banner" : "get-banner"}`), {
      params: { searchText },
    });
    dispath(getBannerSuccess(res.data.listBanner));
    return res.data.listBanner;
  } catch (error) {
    dispath(getBannerFailed());
    console.log(error);
  }
};

export const createBanner = async (banner) => {
  try {
    const res = await axios.post(ApiUrl(`/banner/add-banner`), {
      description: banner.description,
      image: banner.image || "https://www.jaybranding.com/wp-content/uploads/2019/03/mask.png",
      linkTo: banner.linkTo,
      isActive: banner.isActive,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const updateBanner = async (banner) => {
  try {
    const res = await axios.put(ApiUrl(`/banner/update-banner`), {
      _id: banner._id,
      description: banner.description,
      image: banner.image,
      linkTo: banner.linkTo,
      isActive: banner.isActive,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const deleteBanner = async (bannerId) => {
  try {
    const res = await axios.delete(ApiUrl(`/banner/delete-banner`), { data: { _id: bannerId } });
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const deleteMultiBanner = async (bannerIds) => {
  try {
    const res = await axios.delete(ApiUrl(`/banner/delete-multi-banner`), { data: { bannerIds } });
    return res;
  } catch (error) {
    console.log(error);
  }
};
