import React, { useEffect, useState } from "react";
import Box from "../../components/box/Box";
import {
  createProduct,
  deleteMultiProduct,
  getProduct,
  updateProduct,
} from "../../services/ProductService";
import { useDispatch, useSelector } from "react-redux";
import {
  Autocomplete,
  Backdrop,
  Checkbox,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  Pagination,
} from "@mui/material";
import Button from "@mui/material/Button";
import { getBrands } from "../../services/BrandService";
import Searchbar from "../../components/searchbar/Searchbar";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import InputAdornment from "@mui/material/InputAdornment";
import DescriptionIcon from "@mui/icons-material/Description";
import "./Product.css";
import IsDeleteDialog from "../../components/isDeleteDialog/IsDeleteDialog";
import SnackbarAlert from "../../components/snackbarAlert/SnackbarAlert";
import ProductDetail from "./ProductDetail";

function Products({ cate }) {
  const dispatch = useDispatch();
  const brandsState = useSelector((state) => state.brand.brand);

  const [itemPerPage, setItemPerPage] = useState(15);
  const [listBrand, setListBrand] = useState([]);
  const [listProducts, setListProducts] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [totalProducts, setTotalProducts] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [backdrop, setBackdrop] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [textSnackbar, setTextSnackbar] = useState({ text: "Đang xử lý !", severity: "success" });
  const [selectedItem, setSelectedItem] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!listBrand.length) {
          const resBrands = await getBrands(dispatch);
          setListBrand(resBrands);
        }

        const resProducts = await getProduct({
          itemsPerPage: itemPerPage,
          cateId: cate?._id,
          page: currentPage,
          brandIds: selectedBrands,
          searchText: searchText,
        });
        setListProducts(resProducts.listProduct);
        setTotalPages(resProducts.totalPages);
        setTotalProducts(resProducts.totalProducts);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [itemPerPage, currentPage, selectedBrands, searchText]);

  const handleBrandChange = (event, newValue) => {
    const newSelectedBrands = newValue.map((brand) => brand._id);
    setSelectedBrands(newSelectedBrands);
    setCurrentPage(1); // Reset currentPage when brand selection changes
  };
  // Lọc danh sách brand theo wasChoose
  const filteredListBrand = (listBrand, wasChoose) => {
    return listBrand.filter(
      (brand) => wasChoose && wasChoose.length > 0 && wasChoose.includes(brand._id),
    );
  };
  const handleChangeItemPerPage = (event) => {
    setItemPerPage(event.target.value);
  };
  const handleSearch = (text) => {
    setSearchText(text);
  };
  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };
  const handleDeleteMultiProduct = async () => {
    setBackdrop(true);
    const res = await deleteMultiProduct(selectedItems);
    setTextSnackbar({ text: res.data?.message, severity: "success" });
    const updatedListProduct = listProducts.filter(
      (product) => !selectedItems.includes(product._id),
    );
    setListProducts(updatedListProduct);
    setSelectedItems([]);
    setOpenSnackbar(true);
    setBackdrop(false);
    handleCloseDeleteDialog();
  };
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedItems(listProducts.map((item) => item._id));
    } else {
      setSelectedItems([]);
    }
    setIsAllSelected(event.target.checked);
  };
  const handleSelectItem = (event, id) => {
    if (event.target.checked) {
      setSelectedItems((prev) => [...prev, id]);
    } else {
      setSelectedItems((prev) => prev.filter((itemId) => itemId !== id));
    }
  };
  const handeDeleteProduct = async (id) => {
    setBackdrop(true);
    const res = await deleteMultiProduct([id]);
    setTextSnackbar({ text: res.data.message, severity: "success" });
    const updatedListProduct = listProducts.filter((product) => product._id !== id);
    setListProducts(updatedListProduct);
    setOpenSnackbar(true);
    setBackdrop(false);
  };
  const handleOpenCategoryDetail = (item) => {
    setSelectedItem(item);
    setOpenDialog(true);
  };
  const handleCloseCategoryDetail = () => {
    setOpenDialog(false);
    setSelectedItem(null);
  };
  const handleSaveProduct = async (data) => {
    setBackdrop(true);
    let res = { data: { message: "Đã xảy ra lỗi khi lưu sản phẩm!" } };
    try {
      if (data._id) {
        res = await updateProduct(data);
        setTextSnackbar({ text: res.data.message, severity: "success" });
        const updatedListProducts =
          listProducts.length === 0
            ? [data]
            : listProducts.map((product) => {
                if (product._id === data._id) {
                  return data;
                }
                return product;
              });
        setListProducts(updatedListProducts);
      } else {
        res = await createProduct(data);
        setTextSnackbar({ text: res.data.message, severity: "success" });
        setListProducts([...listProducts, res.data.product]);
      }
    } catch (error) {
      console.error(error);
      setTextSnackbar({
        text: res.data.message,
        severity: "error",
      });
    }
    setOpenSnackbar(true);
    setBackdrop(false);
  };
  const handleCurrentPageChange = (event, value) => {
    setCurrentPage(value);
  };
  return (
    <Box>
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <h2 className="title-container">{cate?.cateName || "Tất cả"}</h2>
        <div className="control-container d-flex justify-content-between align-items-center ">
          {selectedItems.length > 0 ? (
            <div className="delete_button item">
              <Button
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={handleOpenDeleteDialog}
              >
                Xoá nhiều
              </Button>
            </div>
          ) : (
            ""
          )}
          <div className="add_button item">
            <Button
              variant="outlined"
              color="success"
              startIcon={<CreateIcon />}
              onClick={() => handleOpenCategoryDetail(null)}
            >
              Tạo mới
            </Button>
          </div>
          <Searchbar onSearch={handleSearch} className="item" />
        </div>
      </div>
      <div className="list_brand">
        {brandsState.isFetching ? (
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        ) : (
          <Autocomplete
            value={filteredListBrand(listBrand, selectedBrands)}
            multiple
            options={listBrand}
            onChange={handleBrandChange}
            getOptionLabel={(option) => option.brandName}
            filterSelectedOptions
            sx={{ width: "100%" }}
            renderInput={(params) => <TextField {...params} label="Chọn Thương hiệu" />}
          />
        )}
      </div>
      <div className="mt-4 table_product">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <TableContainer className="custom-table">
          <Table className="product-table">
            <TableHead>
              <TableRow>
                <TableCell className="checkbox-cell">
                  <Checkbox
                    checked={isAllSelected || selectedItems.length === listProducts.length}
                    onChange={handleSelectAll}
                    indeterminate={
                      selectedItems.length > 0 && selectedItems.length < listProducts.length
                    }
                  />
                </TableCell>
                <TableCell className="name-cell">Tên sản phẩm</TableCell>
                <TableCell className="slug-cell">Slug</TableCell>
                <TableCell className="tag-cell">Tag</TableCell>
                <TableCell className="brand-cell">Thương hiệu</TableCell>
                <TableCell className="price-cell">Giá bán</TableCell>
                <TableCell className="price-cell">Giá gốc</TableCell>
                <TableCell className="delete-cell">Xoá</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listProducts?.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell className="checkbox-cell">
                      <Checkbox
                        checked={selectedItems.includes(item._id)}
                        onChange={(event) => handleSelectItem(event, item._id)}
                      />
                    </TableCell>
                    <TableCell className="name-cell" onClick={() => handleOpenCategoryDetail(item)}>
                      {item.productName}
                    </TableCell>
                    <TableCell className="slug-cell" onClick={() => handleOpenCategoryDetail(item)}>
                      {item.productSlug}
                    </TableCell>
                    <TableCell className="tag-cell" onClick={() => handleOpenCategoryDetail(item)}>
                      {item.tag}
                    </TableCell>
                    <TableCell
                      className="brand-cell"
                      onClick={() => handleOpenCategoryDetail(item)}
                    >
                      {filteredListBrand(listBrand, [item.brand_id])[0].brandName}
                    </TableCell>
                    <TableCell
                      className="price-cell"
                      onClick={() => handleOpenCategoryDetail(item)}
                    >
                      {item.currentPrice}
                    </TableCell>
                    <TableCell
                      className="price-cell"
                      onClick={() => handleOpenCategoryDetail(item)}
                    >
                      {item.originalPrice}
                    </TableCell>
                    <TableCell className="delete-cell">
                      <IconButton
                        aria-label="delete"
                        color="error"
                        onClick={() => handeDeleteProduct(item._id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="mt-4 pagination_product d-flex justify-content-between">
        <FormControl variant="outlined" size="small" className="item">
          <InputLabel id="simple-select-label">Số sản phẩm mỗi trang</InputLabel>
          <Select
            labelId="simple-select-label"
            id="simple-select"
            value={itemPerPage}
            label="Số sản phẩm mỗi trang"
            onChange={handleChangeItemPerPage}
            startAdornment={
              <InputAdornment position="start">
                <DescriptionIcon></DescriptionIcon>
              </InputAdornment>
            }
          >
            <MenuItem value={10}>10 Sản Phẩm/Trang</MenuItem>
            <MenuItem value={15}>15 Sản Phẩm/Trang</MenuItem>
            <MenuItem value={20}>20 Sản Phẩm/Trang</MenuItem>
            <MenuItem value={30}>30 Sản Phẩm/Trang</MenuItem>
          </Select>
        </FormControl>
        <div className="d-flex align-items-center">
          <span>{`Hiển thị ${listProducts.length} trên tổng số ${totalProducts} sản phẩm`}</span>
          <Pagination count={totalPages} color="primary" onChange={handleCurrentPageChange} />
        </div>
      </div>
      <IsDeleteDialog
        title={`Bạn có chắc chắn muốn xoá ${selectedItems.length} sản phẩm không?`}
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleDeleteMultiProduct}
      />
      <SnackbarAlert
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        severity={textSnackbar.severity}
        text={textSnackbar.text}
      />
      <ProductDetail
        item={selectedItem}
        open={openDialog}
        onClose={handleCloseCategoryDetail}
        onSave={handleSaveProduct}
      />
    </Box>
  );
}

export default Products;
