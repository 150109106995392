import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./redux/store";
import "./scss/App.scss";
import "./assets/libs/boxicons-2.1.1/css/boxicons.min.css";
import "./App.css";
import AppRouter from "./AppRouter";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <div className="App">
          <AppRouter />
        </div>
      </PersistGate>
    </Provider>
  );
}

export default App;
