import { createSlice } from "@reduxjs/toolkit";

const brandSlice = createSlice({
  name: "brand",
  initialState: {
    brand: {
      brands: [],
      isFetching: false,
      error: false,
    },
  },
  reducers: {
    getBrandStart: (state) => {
      state.brand.isFetching = true;
    },
    getBrandSuccess: (state, action) => {
      state.brand.isFetching = false;
      state.brand.brands = action.payload;
      state.brand.error = false;
    },
    getBrandFailed: (state) => {
      state.brand.isFetching = false;
      state.brand.error = true;
    },
  },
});

export const { getBrandStart, getBrandSuccess, getBrandFailed } = brandSlice.actions;
export default brandSlice.reducer;
