import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Stack,
} from "@mui/material";
import slugify from "slugify";
import { uploadImageToImgur } from "../../services/UploadImageToImgur";

const BrandDetail = ({ item, open, onClose, onSave }) => {
  const [brandData, setBrandData] = useState({
    brandName: "",
    brandSlug: "",
    description: "",
    image: "",
  });
  const [errors, setErrors] = useState({
    brandName: "",
    brandSlug: "",
    description: "",
    image: "",
  });

  useEffect(() => {
    if (item) {
      setBrandData(item);
    } else {
      setBrandData({
        brandName: "",
        brandSlug: "",
        description: "",
        image: "",
      });
    }
  }, [item]);

  const handleChange = (event) => {
    const { name, value, files } = event.target;
    if (name === "image" && files && files[0]) {
      // Lưu ảnh tạm thời vào localStorage dưới dạng Base64
      const reader = new FileReader();
      reader.onloadend = () => {
        setBrandData((prevData) => ({
          ...prevData,
          image: reader.result,
        }));
      };
      reader.readAsDataURL(files[0]);

      // Xoá lỗi của trường ảnh khi có file ảnh được chọn
      setErrors((prevErrors) => ({
        ...prevErrors,
        image: "",
      }));
    } else {
      setBrandData((prevData) => ({
        ...prevData,
        [name]: value,
      }));

      // Xoá lỗi của trường Đường dẫn khi người dùng nhập
      if (name === "brandSlug" || name === "brandName") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
      }
    }
  };

  const handleSave = async () => {
    if (!validateForm()) {
      return;
    }
    if (brandData.image !== item?.image) {
      // Nếu có thay đổi ảnh mới, thực hiện upload lên imgur
      const imageUrl = await uploadImageToImgur(brandData.image);

      onSave({
        ...brandData,
        image: imageUrl,
      });
    } else {
      // Nếu không có thay đổi ảnh, giữ nguyên link ảnh hiện tại
      onSave(brandData);
    }
    onClose();
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      brandName: "",
      brandSlug: "",
      description: "",
      image: "",
    };

    if (!brandData.brandName) {
      newErrors.brandName = "Vui lòng nhập Tên Thương Hiệu";
      isValid = false;
    }

    if (!brandData.brandSlug) {
      newErrors.brandSlug = "Vui lòng nhập Slug Thương Hiệu";
      isValid = false;
    }

    if (!brandData.description) {
      newErrors.description = "Vui lòng nhập Mô tả Thương Hiệu";
      isValid = false;
    }

    if (!brandData.image) {
      newErrors.image = "Vui lòng chọn ảnh Thương Hiệu";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{item ? "Sửa Thương Hiệu" : "Thêm Thương Hiệu"}</DialogTitle>
      <DialogContent>
        <TextField
          label="Tên Thương Hiệu*"
          name="brandName"
          value={brandData.brandName}
          onChange={handleChange}
          fullWidth
          margin="normal"
          error={Boolean(errors.brandName)}
          helperText={errors.brandName}
        />
        <Stack direction="row" spacing={2}>
          <TextField
            label="Slug*"
            name="brandSlug"
            value={brandData.brandSlug}
            onChange={handleChange}
            fullWidth
            margin="normal"
            error={Boolean(errors.brandSlug)}
            helperText={errors.brandSlug}
          />
          <Button
            variant="contained"
            onClick={() =>
              setBrandData((prevData) => ({
                ...prevData,
                brandSlug: slugify(brandData.brandName, { lower: true, remove: /[*+~.()'"!:@]/g }),
              }))
            }
          >
            Tạo
          </Button>
        </Stack>
        <TextField
          label="Mô tả*"
          name="description"
          value={brandData.description}
          onChange={handleChange}
          fullWidth
          margin="normal"
          error={Boolean(errors.description)}
          helperText={errors.description}
        />
        <input type="file" name="image" accept="image/*" onChange={handleChange} />
        {errors.image && <div style={{ color: "red" }}>{errors.image}</div>}
        {brandData.image && (
          <img src={brandData.image} alt="Preview" style={{ maxWidth: "100%" }} />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Hủy</Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          Lưu
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BrandDetail;
