import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Checkbox,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import { useSelector, useDispatch } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { createBrand, deleteMultiBrand, getBrands, updateBrand } from "../../services/BrandService";
import "./Brand.css";
import SnackbarAlert from "../../components/snackbarAlert/SnackbarAlert";
import Searchbar from "../../components/searchbar/Searchbar";
import BrandDetail from "./BrandDetail";
import IsDeleteDialog from "../../components/isDeleteDialog/IsDeleteDialog";

function Brand() {
  const brands = useSelector((state) => {
    return state.brand.brand;
  });
  const dispatch = useDispatch();
  const [listBrand, setListBrand] = useState([]);
  const [backdrop, setBackdrop] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [textSnackbar, setTextSnackbar] = useState({ text: "Đang xử lý !", severity: "success" });
  const [searchText, setSearchText] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  useEffect(() => {
    const fetchBrand = async () => {
      const res = await getBrands(dispatch, searchText);
      setListBrand(res);
    };
    fetchBrand();
    // eslint-disable-next-line
  }, [searchText]);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedItems(listBrand.map((item) => item._id));
    } else {
      setSelectedItems([]);
    }
    setIsAllSelected(event.target.checked);
  };

  const handleSelectItem = (event, id) => {
    if (event.target.checked) {
      setSelectedItems((prev) => [...prev, id]);
    } else {
      setSelectedItems((prev) => prev.filter((itemId) => itemId !== id));
    }
  };

  const handeDeleteBrand = async (id) => {
    setBackdrop(true);
    const res = await deleteMultiBrand([id]);
    setTextSnackbar({ text: res.data.message, severity: "success" });
    const updatedListBrand = listBrand.filter((brand) => brand._id !== id);
    setListBrand(updatedListBrand);
    setOpenSnackbar(true);
    setBackdrop(false);
  };

  const handleDeleteMultiBrand = async () => {
    setBackdrop(true);
    const res = await deleteMultiBrand(selectedItems);
    setTextSnackbar({ text: res.data?.message, severity: "success" });
    const updatedListBrand = listBrand.filter((brand) => !selectedItems.includes(brand._id));
    setListBrand(updatedListBrand);
    setSelectedItems([]);
    setOpenSnackbar(true);
    setBackdrop(false);
    handleCloseDeleteDialog();
  };

  const handleOpenBrandDetail = (item) => {
    setSelectedItem(item);
    setOpenDialog(true);
  };

  const handleCloseBrandDetail = () => {
    setOpenDialog(false);
    setSelectedItem(null);
  };

  const handleSaveBrand = async (data) => {
    setBackdrop(true);
    let res = { data: { message: "Đã xảy ra lỗi khi lưu thương hiệu!" } };
    try {
      if (data._id) {
        res = await updateBrand(data);
        setTextSnackbar({ text: res.data.message, severity: "success" });
        const updatedListBrand =
          listBrand.length === 0
            ? [data]
            : listBrand.map((brand) => {
                if (brand._id === data._id) {
                  return data;
                }
                return brand;
              });
        setListBrand(updatedListBrand);
      } else {
        res = await createBrand(data);
        setTextSnackbar({ text: res.data.message, severity: "success" });
        setListBrand([...listBrand, res.data.brand]);
      }
    } catch (error) {
      console.error(error);
      setTextSnackbar({
        text: res.data.message,
        severity: "error",
      });
    }
    setOpenSnackbar(true);
    setBackdrop(false);
  };

  const handleSearch = (text) => {
    setSearchText(text);
  };

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  return (
    <div>
      <div className="head_container d-flex justify-content-between m-0">
        <h2 className="title_container">Danh Sách Thương Hiệu</h2>
        <div className="control_container d-flex justify-content-end">
          {selectedItems.length > 0 ? (
            <div className="delete_button">
              <Button
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={handleOpenDeleteDialog}
              >
                Xoá nhiều
              </Button>
            </div>
          ) : (
            ""
          )}
          <div className="add_button">
            <Button
              variant="outlined"
              color="success"
              startIcon={<CreateIcon />}
              onClick={() => handleOpenBrandDetail(null)}
            >
              Tạo mới
            </Button>
          </div>
          <Searchbar onSearch={handleSearch} />
        </div>
      </div>
      {brands.isFetching ? (
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={backdrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <TableContainer className="custom-table">
            <Table className="brand-table">
              <TableHead>
                <TableRow>
                  <TableCell className="checkbox-cell">
                    <Checkbox
                      checked={isAllSelected || selectedItems.length === listBrand.length}
                      onChange={handleSelectAll}
                      indeterminate={
                        selectedItems.length > 0 && selectedItems.length < listBrand.length
                      }
                    />
                  </TableCell>
                  <TableCell className="image-cell">Ảnh</TableCell>
                  <TableCell className="brandName-cell">Tên thương hiệu</TableCell>
                  <TableCell className="brandSlug-cell">Slug</TableCell>
                  <TableCell className="description-cell">Mô tả</TableCell>
                  <TableCell className="delete-cell">Xoá</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listBrand?.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell className="checkbox-cell">
                        <Checkbox
                          checked={selectedItems.includes(item._id)}
                          onChange={(event) => handleSelectItem(event, item._id)}
                        />
                      </TableCell>
                      <TableCell className="image-cell" onClick={() => handleOpenBrandDetail(item)}>
                        <LazyLoadImage
                          src={item.image}
                          effect="blur"
                          alt=""
                          height="100"
                          placeholderSrc={item.image}
                        />
                      </TableCell>
                      <TableCell
                        className="brandName-cell"
                        onClick={() => handleOpenBrandDetail(item)}
                      >
                        {item.brandName}
                      </TableCell>
                      <TableCell
                        className="brandSlug-cell"
                        onClick={() => handleOpenBrandDetail(item)}
                      >
                        {item.brandSlug}
                      </TableCell>
                      <TableCell
                        className="description-cell"
                        onClick={() => handleOpenBrandDetail(item)}
                      >
                        {item.description}
                      </TableCell>
                      <TableCell className="delete-cell">
                        <IconButton
                          aria-label="delete"
                          color="error"
                          onClick={() => handeDeleteBrand(item._id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      <SnackbarAlert
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        severity={textSnackbar.severity}
        text={textSnackbar.text}
      />
      <BrandDetail
        item={selectedItem}
        open={openDialog}
        onClose={handleCloseBrandDetail}
        onSave={handleSaveBrand}
      />
      <IsDeleteDialog
        title={`Bạn có chắc chắn muốn xoá ${selectedItems.length} thương hiệu không?`}
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleDeleteMultiBrand}
      />
    </div>
  );
}

export default Brand;
