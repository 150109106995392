import axios from "axios";
import ApiUrl from "../utils/RewriteApi";
import { getBrandStart, getBrandSuccess, getBrandFailed } from "../redux/slice/BrandSlice";

export const getBrands = async (dispatch, searchText) => {
  dispatch(getBrandStart());
  try {
    const res = await axios.get(ApiUrl(`/brand/get-brand`), {
      params: { searchText },
    });
    dispatch(getBrandSuccess(res.data.listBrand));
    return res.data.listBrand;
  } catch (error) {
    dispatch(getBrandFailed());
    console.log(error);
  }
};

export const createBrand = async (brand) => {
  try {
    const res = await axios.post(ApiUrl(`/brand/add-brand`), {
      brandName: brand.brandName,
      brandSlug: brand.brandSlug,
      description: brand.description,
      image:
        brand.image ||
        "https://images.pexels.com/photos/335257/pexels-photo-335257.jpeg?cs=srgb&dl=pexels-eprism-studio-335257.jpg&fm=jpg",
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const updateBrand = async (brand) => {
  try {
    const res = await axios.put(ApiUrl(`/brand/update-brand`), {
      _id: brand._id,
      brandName: brand.brandName,
      brandSlug: brand.brandSlug,
      description: brand.description,
      image: brand.image,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const deleteMultiBrand = async (brandIds) => {
  try {
    const res = await axios.delete(ApiUrl(`/brand/delete-brand`), {
      data: { brandIds },
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};
