import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";

function IsDeleteDialog({ title, open, onClose, onConfirm }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Xác nhận xoá</DialogTitle>
      <DialogContent>
        <DialogContentText>{title}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="error" onClick={onClose}>
          Không
        </Button>
        <Button variant="outlined" color="error" onClick={onConfirm}>
          Có
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default IsDeleteDialog;
