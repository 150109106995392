import React from "react";
import { styled } from "@mui/system";
import Tabs from "@mui/base/Tabs";
import TabsList from "@mui/base/TabsList";
import TabPanel from "@mui/base/TabPanel";
import { buttonClasses } from "@mui/base/Button";
import Tab, { tabClasses } from "@mui/base/Tab";
import Category from "../../layout/category/Category";
import Brand from "../../layout/brand/Brand";
import { Box } from "@mui/material";
import CategoryMapBrand from "../../layout/category-brand/CategoryMapBrand";

const purple = {
  50: "#F5E3FC",
  100: "#E0BDF8",
  200: "#D388F5",
  300: "#C057F2",
  400: "#B338EE",
  500: "#A500EB",
  600: "#9200D9",
  700: "#7800AF",
  800: "#60008B",
  900: "#44005E",
};
const grey = {
  50: "#f6f8fa",
  100: "#eaeef2",
  200: "#d0d7de",
  300: "#afb8c1",
  400: "#8c959f",
  500: "#6e7781",
  600: "#57606a",
  700: "#424a53",
  800: "#32383f",
  900: "#24292f",
};

const StyledTab = styled(Tab)`
  font-family: "IBM Plex Sans", sans-serif;
  color: #fff;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
  background-color: transparent;
  width: 100%;
  padding: 10px 12px;
  margin: 6px;
  border: none;
  border-radius: 7px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${purple[400]};
  }

  &:focus {
    color: #fff;
    outline: 3px solid ${purple[200]};
  }

  &.${tabClasses.selected} {
    background-color: #fff;
    color: ${purple[600]};
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const StyledTabPanel = styled(TabPanel)(
  ({ theme }) => `
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  padding: 20px 12px;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  border-radius: 12px;
  opacity: 0.9;
  `,
);

const StyledTabsList = styled(TabsList)(
  ({ theme }) => `
  min-width: 400px;
  background-color: #8624DB;
  border-radius: 12px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  box-shadow: 0px 4px 30px ${theme.palette.mode === "dark" ? grey[900] : grey[200]};
  `,
);

function Categories() {
  return (
    <Box sx={{ width: "100%" }}>
      <Tabs defaultValue="category">
        <StyledTabsList>
          <StyledTab value="category">Thể Loại</StyledTab>
          <StyledTab value="brand">Thương Hiệu</StyledTab>
          <StyledTab value="category_brand">Liên kết Thể Loại & Thương Hiệu</StyledTab>
        </StyledTabsList>
        <StyledTabPanel value="category">
          <Category />
        </StyledTabPanel>
        <StyledTabPanel value="brand">
          <Brand />
        </StyledTabPanel>
        <StyledTabPanel value="category_brand">
          <CategoryMapBrand />
        </StyledTabPanel>
      </Tabs>
    </Box>
  );
}

export default Categories;
