import axios from "axios";
import ApiUrl from "../utils/RewriteApi";

export const getCategoryMapBrand = async () => {
  try {
    const res = await axios.get(ApiUrl("/category-map-brand/get"));
    return res.data.categoryMapBrand;
  } catch (error) {
    console.log(error);
  }
};

export const addCategoryMapBrand = async (categoryId, brandIds) => {
  try {
    const res = await axios.post(ApiUrl("/category-map-brand/create"), {
      categoryId,
      brandIds,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};
