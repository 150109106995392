import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import { useSelector, useDispatch } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  createBanner,
  deleteBanner,
  deleteMultiBanner,
  getBanner,
  updateBanner,
} from "../../services/BannerService";
import "./Banner.css";
import SnackbarAlert from "../../components/snackbarAlert/SnackbarAlert";
import Searchbar from "../../components/searchbar/Searchbar";
import BannerDetail from "./BannerDetail";
import IsDeleteDialog from "../../components/isDeleteDialog/IsDeleteDialog";

function Banner() {
  const banners = useSelector((state) => {
    return state.banner.banner;
  });
  const dispatch = useDispatch();
  const [listBanner, setListBanner] = useState([]);
  const [isAll, setIsAll] = useState(true);
  const [backdrop, setBackdrop] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [textSnackbar, setTextSnackbar] = useState({ text: "Đang xử lý !", severity: "success" });
  const [searchText, setSearchText] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  useEffect(() => {
    const fetchBanner = async () => {
      const res = await getBanner(dispatch, isAll, searchText);
      setListBanner(res);
    };
    fetchBanner();
    // eslint-disable-next-line
  }, [isAll, searchText]);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedItems(listBanner.map((item) => item._id));
    } else {
      setSelectedItems([]);
    }
    setIsAllSelected(event.target.checked);
  };
  const handleSelectItem = (event, id) => {
    if (event.target.checked) {
      setSelectedItems((prev) => [...prev, id]);
    } else {
      setSelectedItems((prev) => prev.filter((itemId) => itemId !== id));
    }
  };
  const handleIsActiveColor = (isActive) => {
    return isActive ? "green" : "red";
  };
  const handleIsActive = async (item, isActiveItem) => {
    setBackdrop(true);
    const newItem = { ...item, isActive: isActiveItem };
    await updateBanner(newItem);
    const updatedListBanner = listBanner.map((banner) => {
      if (banner._id === item._id) {
        return { ...banner, isActive: !banner.isActive };
      }
      return banner;
    });
    setListBanner(updatedListBanner);
    setBackdrop(false);
  };
  const handleDeleteBanner = async (id) => {
    setBackdrop(true);
    const res = await deleteBanner(id);
    setTextSnackbar({ text: res.data.message, severity: "success" });
    const updatedListBanner = listBanner.filter((banner) => banner._id !== id);
    setListBanner(updatedListBanner);
    setOpenSnackbar(true);
    setBackdrop(false);
  };
  const handleDeleteMultiBanner = async () => {
    setBackdrop(true);
    const res = await deleteMultiBanner(selectedItems);
    setTextSnackbar({ text: res.data.message, severity: "success" });
    const updatedListBanner = listBanner.filter((banner) => !selectedItems.includes(banner._id));
    setListBanner(updatedListBanner);
    setSelectedItems([]);
    setOpenSnackbar(true);
    setBackdrop(false);
    handleCloseDeleteDialog();
  };
  const handleOpenBannerDetail = (item) => {
    setSelectedItem(item);
    setOpenDialog(true);
  };

  const handleCloseBannerDetail = () => {
    setOpenDialog(false);
    setSelectedItem(null);
  };

  const handleSaveBanner = async (data) => {
    setBackdrop(true);
    try {
      if (data._id) {
        const res = await updateBanner(data);
        setTextSnackbar({ text: res.data.message, severity: "success" });
        const updatedListBanner =
          listBanner === 0
            ? [data]
            : listBanner.map((banner) => {
                if (banner._id === data._id) {
                  return data;
                }
                return banner;
              });
        setListBanner(updatedListBanner);
      } else {
        const res = await createBanner(data);
        setTextSnackbar({ text: res.data.message, severity: "success" });
        setListBanner([...listBanner, res.data.banner]);
      }
    } catch (error) {
      console.log(error);
      setTextSnackbar({ text: "Đã xảy ra lỗi khi lưu banner!", severity: "error" });
    }
    setOpenSnackbar(true);
    setBackdrop(false);
  };

  const handleSearch = (text) => {
    setSearchText(text);
  };
  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  return (
    <div>
      <div className="head_container d-flex justify-content-between m-0">
        <h2 className="title_container">Danh Sách Banner</h2>
        <div className="control_container d-flex justify-content-end">
          {selectedItems.length > 0 ? (
            <div className="delete_button">
              <Button
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={handleOpenDeleteDialog}
              >
                Xoá nhiều
              </Button>
            </div>
          ) : (
            ""
          )}
          <div className="add_button">
            <Button
              variant="outlined"
              color="success"
              startIcon={<CreateIcon />}
              onClick={() => handleOpenBannerDetail(null)}
            >
              Tạo mới
            </Button>
          </div>
          <Searchbar onSearch={handleSearch} />
          <div className="choose_all_banner">
            <FormControlLabel
              control={<Switch onChange={() => setIsAll(!isAll)} value={isAll} />}
              label={isAll ? "Tất cả banner" : "Đang sử dụng"}
              labelPlacement="start"
            />
          </div>
        </div>
      </div>
      {banners.isFetching ? (
        <CircularProgress />
      ) : (
        <>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={backdrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <TableContainer className="custom-table">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="checkbox-cell">
                    <Checkbox
                      checked={isAllSelected || selectedItems.length === listBanner.length}
                      onChange={handleSelectAll}
                      indeterminate={
                        selectedItems.length > 0 && selectedItems.length < listBanner.length
                      }
                    />
                  </TableCell>
                  <TableCell className="image-cell">Ảnh</TableCell>
                  <TableCell className="description-cell">Mô tả</TableCell>
                  <TableCell className="linkTo-cell">Đường dẫn</TableCell>
                  <TableCell className="activity-cell">Hoạt động</TableCell>
                  <TableCell className="delete-cell">Xoá</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listBanner?.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell className="checkbox-cell">
                        <Checkbox
                          checked={selectedItems.includes(item._id)}
                          onChange={(event) => handleSelectItem(event, item._id)}
                        />
                      </TableCell>
                      <TableCell
                        className="image-cell"
                        onClick={() => handleOpenBannerDetail(item)}
                      >
                        <LazyLoadImage
                          src={item.image}
                          effect="blur"
                          alt=""
                          height="100"
                          placeholderSrc={item.image}
                        />
                      </TableCell>
                      <TableCell
                        className="description-cell"
                        onClick={() => handleOpenBannerDetail(item)}
                      >
                        {item.description}
                      </TableCell>
                      <TableCell
                        className="linkTo-cell"
                        onClick={() => handleOpenBannerDetail(item)}
                      >
                        {item.linkTo}
                      </TableCell>
                      <TableCell className="activity-cell">
                        <Button
                          variant="contained"
                          style={{ backgroundColor: handleIsActiveColor(item.isActive) }}
                          onClick={() => handleIsActive(item, !item.isActive)}
                        >
                          {item.isActive ? "Chạy" : "Tắt"}
                        </Button>
                      </TableCell>
                      <TableCell className="delete-cell">
                        <IconButton
                          aria-label="delete"
                          color="error"
                          onClick={() => handleDeleteBanner(item._id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      <SnackbarAlert
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        severity={textSnackbar.severity}
        text={textSnackbar.text}
      />
      <BannerDetail
        item={selectedItem}
        open={openDialog}
        onClose={handleCloseBannerDetail}
        onSave={handleSaveBanner}
      />
      <IsDeleteDialog
        title={`Bạn có chắc chắn muốn xoá ${selectedItems.length} banner không?`}
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleDeleteMultiBanner}
      />
    </div>
  );
}

export default Banner;
