import React from "react";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ImgurUploadAdapterPlugin from "./UploadAdapter";

function CustomCKEditor({ data, onChange }) {
  return (
    <CKEditor
      editor={ClassicEditor}
      data={data}
      config={{
        extraPlugins: [ImgurUploadAdapterPlugin], // Sử dụng custom UploadAdapter
        ckfinder: false, // Tắt CKFinder
      }}
      onChange={onChange}
    />
  );
}

export default CustomCKEditor;
