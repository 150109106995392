import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import { Tabs } from "@mui/base/";
import { TabsList } from "@mui/base";
import { TabPanel } from "@mui/base";
import { buttonClasses } from "@mui/base/Button";
import { Tab, tabClasses } from "@mui/base/";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "../../services/CategoryService";
import { Box, CircularProgress } from "@mui/material";
import Products from "../../layout/products/Products";
import "./ProductScreen.css";

function ProductsScreen() {
  const dispatch = useDispatch();
  const categories = useSelector((state) => {
    return state.category.category;
  });
  const [listCategory, setListCategory] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const resCate = await getCategories(dispatch);
      setListCategory(resCate);
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div>
        <h2>Sản phẩm</h2>
      </div>
      {categories.isFetching ? (
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      ) : (
        <StyledTabs defaultValue={0} orientation="vertical">
          <StyledTabsList>
            <StyledTab value={0}>Tất cả</StyledTab>
            {listCategory?.map((item) => {
              return (
                <StyledTab key={item._id} value={item._id}>
                  {item.cateName}
                </StyledTab>
              );
            })}
          </StyledTabsList>
          <StyledTabPanel value={0}>
            <Products />
          </StyledTabPanel>
          {listCategory?.map((item) => {
            return (
              <StyledTabPanel key={item._id} value={item._id}>
                <Products cate={item} />
              </StyledTabPanel>
            );
          })}
        </StyledTabs>
      )}
    </div>
  );
}

export default ProductsScreen;

const purple = {
  50: "#F5E3FC",
  100: "#E0BDF8",
  200: "#D388F5",
  300: "#C057F2",
  400: "#B338EE",
  500: "#A500EB",
  600: "#9200D9",
  700: "#7800AF",
  800: "#60008B",
  900: "#44005E",
};
const grey = {
  50: "#f6f8fa",
  100: "#eaeef2",
  200: "#d0d7de",
  300: "#afb8c1",
  400: "#8c959f",
  500: "#6e7781",
  600: "#57606a",
  700: "#424a53",
  800: "#32383f",
  900: "#24292f",
};

const StyledTab = styled(Tab)`
  font-family: "IBM Plex Sans", sans-serif;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  background-color: transparent;
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 7px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${purple[400]};
  }

  &:focus {
    color: #fff;
    outline: 3px solid ${purple[200]};
  }

  &.${buttonClasses.focusVisible} {
    background-color: #fff;
    color: ${purple[600]};
  }

  &.${tabClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.${tabClasses.selected} {
    background-color: #fff;
    color: ${purple[600]};
  }
`;

const StyledTabPanel = styled(TabPanel)`
  width: 100%;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 0.875rem;
`;

const StyledTabs = styled(Tabs)`
  display: flex;
  gap: 16px;
  width: 100%;
`;

const StyledTabsList = styled(TabsList)(
  ({ theme }) => `
  min-width: 250px;
  min-height: 75vh;
  background-color: ${purple[500]};
  border-radius: 12px;
  margin-bottom: 16px;
  display: flex;
  padding: 6px;
  gap: 12px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  box-shadow: 0px 4px 8px ${theme.palette.mode === "dark" ? grey[900] : grey[200]};
  `,
);
