import { createSlice } from "@reduxjs/toolkit";

const bannerSlice = createSlice({
  name: "banner",
  initialState: {
    banner: {
      banners: [],
      isFetching: false,
      error: false,
    },
  },
  reducers: {
    getBannerStart: (state) => {
      state.banner.isFetching = true;
    },
    getBannerSuccess: (state, action) => {
      state.banner.isFetching = false;
      state.banner.banners = action.payload;
      state.banner.error = false;
    },
    getBannerFailed: (state) => {
      state.banner.isFetching = false;
      state.banner.error = true;
    },
  },
});

export const { getBannerStart, getBannerSuccess, getBannerFailed } = bannerSlice.actions;
export default bannerSlice.reducer;
