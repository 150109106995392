import React, { forwardRef, useEffect, useState } from "react";
import slugify from "slugify";
import { uploadImageToImgur } from "../../services/UploadImageToImgur";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Stack,
  Autocomplete,
  Box,
  ImageList,
  ImageListItem,
  IconButton,
  Divider,
} from "@mui/material";
import { useSelector } from "react-redux";
import { NumericFormat } from "react-number-format";
import PropTypes from "prop-types";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomCKEditor from "../../components/custom-ckeditor/CustomCKEditor";
import AddIcon from "@mui/icons-material/Add";

function ProductDetail({ item, open, onClose, onSave }) {
  const brandsState = useSelector((state) => state.brand.brand);
  const categoriesState = useSelector((state) => {
    return state.category.category;
  });

  const [productData, setProductData] = useState({
    productName: "",
    productSlug: "",
    description: "",
    tag: "",
    currentPrice: 0,
    originalPrice: 0,
    options: [{ key: "", value: "" }], // An array of Option objects
    category_id: null, // Change to Schema.Types.ObjectId or the appropriate type
    brand_id: null, // Change to Schema.Types.ObjectId or the appropriate type
    image: [], // An array of image URLs
  });

  const [errors, setErrors] = useState({
    productName: "",
    productSlug: "",
    description: "",
    category_id: "",
    brand_id: "",
    price: "",
    image: "",
    optionKey: "",
    optionValue: "",
  });
  useEffect(() => {
    if (item) {
      setProductData(item);
    } else {
      setProductData({
        productName: "",
        productSlug: "",
        description: "",
        tag: "",
        options: [{ key: "", value: " " }],
        rateScore: 0,
        rateTimes: 0,
        category_id: null,
        brand_id: null,
        image: [],
        currentPrice: 0,
        originalPrice: 0,
      });
    }
    return () => {
      setProductData({
        productName: "",
        productSlug: "",
        description: "",
        tag: "",
        options: [{ key: "", value: "" }],
        rateScore: 0,
        rateTimes: 0,
        category_id: null,
        brand_id: null,
        image: [],
        currentPrice: 0,
        originalPrice: 0,
      });
    };
  }, [item]);

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      productName: "",
      productSlug: "",
      description: "",
      category_id: "",
      brand_id: "",
      currentPrice: "",
      originalPrice: "",
      image: "",
      optionKey: "",
      optionValue: "",
    };

    if (!productData.productName) {
      newErrors.productName = "Vui lòng nhập Tên sản phẩm";
      isValid = false;
    }

    if (!productData.productSlug) {
      newErrors.productSlug = "Vui lòng nhập Slug sản phẩm";
      isValid = false;
    }

    if (!productData.description) {
      newErrors.description = "Vui lòng nhập Mô tả sản phẩm";
      isValid = false;
    }

    if (!productData.category_id) {
      newErrors.category_id = "Bạn chưa chọn Thể loại";
      isValid = false;
    }
    if (!productData.brand_id) {
      newErrors.brand_id = "Bạn chưa chọn Thương hiệu";
      isValid = false;
    }
    if (!productData.currentPrice) {
      newErrors.currentPrice = "Vui lòng nhập đúng giá bán";
      isValid = false;
    }
    if (!productData.originalPrice) {
      newErrors.originalPrice = "Vui lòng nhập đúng giá gốc";
      isValid = false;
    }
    if (productData.image.length === 0) {
      newErrors.image = "Vui lòng ít nhất 1 ảnh cho sản phẩm";
      isValid = false;
    }
    productData.options.forEach((item) => {
      if (item.key.trim() === "") {
        newErrors.optionKey = "Vui lòng xem lại các nhóm";
        isValid = false;
      }
      for (let i = 0; i < item.value.split("$/").length; i++) {
        if (item.value.split("$/")[i].trim() === "") {
          newErrors.optionValue = `Vui lòng xem lại các phân loại hàng ${item.key}`;
          isValid = false;
        }
      }
    });

    setErrors(newErrors);
    return isValid;
  };
  const handleChange = (event) => {
    const { name, value, files } = event.target;
    if (name === "image" && files && files.length > 0) {
      for (var i = 0; i < files.length; i++) {
        // Lưu ảnh tạm thời vào localStorage dưới dạng Base64
        const reader = new FileReader();
        reader.onloadend = () => {
          setProductData((prevData) => ({
            ...prevData,
            image: [...prevData.image, reader.result],
          }));
        };
        reader.readAsDataURL(files[i]);
      }

      // Xoá lỗi của trường ảnh khi có file ảnh được chọn
      setErrors((prevErrors) => ({
        ...prevErrors,
        image: "",
      }));
    } else if (name === "currentPrice" || name === "originalPrice") {
      const newValue = parseInt(value);
      // Kiểm tra xem có phải là một số không âm hay không
      if (!isNaN(newValue) && newValue >= 0) {
        setProductData((prevData) => ({
          ...prevData,
          [name]: newValue,
        }));
      } else {
        setProductData((prevData) => ({
          ...prevData,
          [name]: 0,
        }));
      }
    } else {
      setProductData((prevData) => ({
        ...prevData,
        [name]: value,
      }));

      // Xoá lỗi của trường Đường dẫn khi người dùng nhập
      if (
        name === "productName" ||
        name === "productSlug" ||
        name === "description" ||
        name === "category_id" ||
        name === "options" ||
        name === "brand_id"
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
      }
    }
  };

  const handleSave = async () => {
    if (!validateForm()) {
      return;
    }
    const newImgs = [];
    for (const img of productData.image) {
      if (item?.image.includes(img)) {
        newImgs.push(img);
      } else {
        const imageUrl = await uploadImageToImgur(img);
        newImgs.push(imageUrl);
      }
    }

    onSave({
      ...productData,
      image: newImgs,
    });

    onClose();
  };
  const handClose = () => {
    setErrors({
      productName: "",
      productSlug: "",
      description: "",
      category_id: "",
      brand_id: "",
    });
    onClose();
  };
  // Lọc danh sách brand theo wasChoose
  const filteredListOption = (listBrand, wasChoose) => {
    const selectedBrand = listBrand.find((brand) => wasChoose && wasChoose === brand._id);
    return selectedBrand || null;
  };
  const handOnChangeBrand = (event, newValue) => {
    const newChoose = newValue ? newValue._id : null;
    setProductData((preData) => ({ ...preData, brand_id: newChoose }));
  };
  const handOnChangeCate = (event, newValue) => {
    const newChoose = newValue ? newValue._id : null;
    setProductData((preData) => ({ ...preData, category_id: newChoose }));
  };
  const handleDeleteImage = (index) => {
    const updatedImages = [...productData.image];
    updatedImages.splice(index, 1);
    setProductData((prevData) => ({
      ...prevData,
      image: updatedImages,
    }));
  };
  const handleChangeCKEditor = (event, editor) => {
    const data = editor.getData();
    setProductData((prevData) => ({
      ...prevData,
      description: data,
    }));
  };

  const handleAddOptionValue = (keyOption) => {
    const newOption = productData.options.map((item) => {
      if (item.key === keyOption) {
        return { key: item.key, value: `${item.value}$/` };
      }
      return item;
    });

    setProductData((preData) => ({
      ...preData,
      options: newOption,
    }));
  };
  const handleAddOption = () => {
    setProductData((preData) => ({
      ...preData,
      options: [...preData.options, { key: "", value: " " }],
    }));
  };

  const handleDeleteOptionValue = (keyOption, index) => {
    const newOption = productData.options.map((item) => {
      if (item.key === keyOption) {
        const arrOption = item.value.split("$/");
        arrOption.splice(index, 1);
        return { key: item.key, value: arrOption.join("$/") };
      }
      return item;
    });

    setProductData((preData) => ({
      ...preData,
      options: newOption,
    }));
  };
  const handleDeleteOption = (keyOption) => {
    const newOption = productData.options.filter((item) => item.key !== keyOption);
    setProductData((preData) => ({
      ...preData,
      options: newOption,
    }));
  };

  const handleChangeOptionValue = (keyOption, index, value) => {
    const newOption = productData.options.map((item) => {
      if (item.key === keyOption) {
        const arrOption = item.value.split("$/");
        arrOption[index] = value;
        return { key: item.key, value: arrOption.join("$/") };
      }
      return item;
    });

    setProductData((preData) => ({
      ...preData,
      options: newOption,
    }));
  };
  const handleChangeOption = (keyOption, value) => {
    const newOption = productData.options.map((item) => {
      if (item.key === keyOption) {
        return { key: value, value: item.value };
      }
      return item;
    });

    setProductData((preData) => ({
      ...preData,
      options: newOption,
    }));
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth className="product_dialog">
      <DialogTitle>{item ? "Sửa Sản Phẩm" : "Thêm Sản Phẩm"}</DialogTitle>
      <DialogContent>
        <TextField
          label="Tên Sản Phẩm*"
          name="productName"
          value={productData.productName}
          onChange={handleChange}
          fullWidth
          margin="normal"
          error={Boolean(errors.productName)}
          helperText={errors.productName}
        />
        <Stack direction="row" spacing={2}>
          <TextField
            label="Slug*"
            name="productSlug"
            value={productData.productSlug}
            onChange={handleChange}
            fullWidth
            margin="normal"
            error={Boolean(errors.productSlug)}
            helperText={errors.productSlug}
          />
          <Button
            variant="contained"
            onClick={() =>
              setProductData((prevData) => ({
                ...prevData,
                productSlug: slugify(productData.productName, {
                  lower: true,
                  remove: /[*+~.()'"!:@]/g,
                }),
              }))
            }
          >
            Tạo
          </Button>
        </Stack>
        <Stack direction="row" spacing={2} className="mt-3 row-categories">
          <Autocomplete
            value={filteredListOption(categoriesState.categories, productData.category_id)}
            options={categoriesState.categories}
            getOptionLabel={(option) => option.cateName}
            filterSelectedOptions
            sx={{ width: "50%" }}
            onChange={handOnChangeCate}
            renderOption={(props, option) => (
              <Box
                component="li"
                className="d-flex align-item-center"
                sx={{
                  "& > img": { objectFit: "cover", width: "2rem", height: "2rem" },
                }}
                {...props}
                height="20px"
              >
                <img loading="lazy" src={option.image} alt="" />
                {option.cateName}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Chọn Thể loại"
                error={Boolean(errors.category_id)}
                helperText={errors.category_id}
              />
            )}
          />
          <Autocomplete
            value={filteredListOption(brandsState.brands, productData.brand_id)}
            options={brandsState.brands}
            getOptionLabel={(option) => option.brandName}
            filterSelectedOptions
            sx={{ width: "50%" }}
            onChange={handOnChangeBrand}
            renderOption={(props, option) => (
              <Box
                component="li"
                className="d-flex align-item-center"
                sx={{
                  "& > img": { objectFit: "cover", width: "2.5rem" },
                }}
                {...props}
                height="20px"
              >
                <img loading="lazy" src={option.image} alt="" />
                {option.brandName}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Chọn Thương hiệu"
                error={Boolean(errors.brand_id)}
                helperText={errors.brand_id}
              />
            )}
          />
        </Stack>
        <Box className="mt-3 row-tag">
          <TextField
            label="Tag"
            name="tag"
            value={productData.tag}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
        </Box>
        <Stack direction="row" spacing={2} className="mt-3 row-price">
          <TextField
            label="Giá bán*"
            name="currentPrice"
            value={productData.currentPrice}
            onChange={handleChange}
            fullWidth
            margin="normal"
            error={Boolean(errors.currentPrice)}
            helperText={errors.currentPrice}
            InputProps={{
              inputComponent: NumericFormatCustom,
            }}
          />
          <TextField
            label="Giá gốc*"
            name="originalPrice"
            value={productData.originalPrice}
            onChange={handleChange}
            fullWidth
            margin="normal"
            error={Boolean(errors.originalPrice)}
            helperText={errors.originalPrice}
            InputProps={{
              inputComponent: NumericFormatCustom,
            }}
          />
        </Stack>
        <Box className="mt-3 row-option">
          <label htmlFor="option">Phân loại sản phẩm:</label>
          <div className="d-flex">
            {<p style={{ color: "red", fontSize: "0.75rem", width: "47%" }}>{errors.optionKey}</p>}
            {errors.optionValue && (
              <p style={{ color: "red", fontSize: "0.75rem", width: "50%" }}>
                {errors.optionValue}
              </p>
            )}
          </div>
          {productData.options.map((option, index) => {
            return (
              <>
                <Stack direction="row" spacing={2} key={index} className="mb-3">
                  <TextField
                    required
                    label="Nhóm phân loại"
                    variant="standard"
                    fullWidth
                    value={option.key}
                    onChange={(e) => handleChangeOption(option.key, e.target.value)}
                  />
                  <Box width="100%">
                    {option.value &&
                      option.value.split("$/").map((value, index) => (
                        <Box key={index} display="flex" alignItems="center">
                          <TextField
                            label="Phân loại hàng"
                            variant="standard"
                            fullWidth
                            value={value}
                            onChange={(e) =>
                              handleChangeOptionValue(option.key, index, e.target.value)
                            }
                          />
                          <Button
                            size="small"
                            onClick={() => handleDeleteOptionValue(option.key, index)}
                          >
                            <DeleteIcon />
                          </Button>
                        </Box>
                      ))}
                    <Button
                      variant="outlined"
                      size="small"
                      fullWidth
                      className="mt-2"
                      onClick={() => handleAddOptionValue(option.key)}
                    >
                      <AddIcon />
                    </Button>
                  </Box>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => handleDeleteOption(option.key)}
                  >
                    <DeleteIcon />
                  </Button>
                </Stack>
                <Divider className="mb-2" />
              </>
            );
          })}
          <Button variant="contained" size="small" fullWidth onClick={() => handleAddOption()}>
            <AddIcon />
          </Button>
        </Box>
        <Box className="mt-3 row-description">
          <label htmlFor="editor">Mô tả:</label>
          {errors.description && (
            <p style={{ color: "red", fontSize: "0.75rem" }}>{errors.description}</p>
          )}
          <CustomCKEditor data={productData.description} onChange={handleChangeCKEditor} />
        </Box>
        <Box className="mt-3 row-image">
          <label htmlFor="image" className="mr-2">
            Hình ảnh:
          </label>
          <input type="file" multiple name="image" accept="image/*" onChange={handleChange} />
          {errors.image && <p style={{ color: "red", fontSize: "0.75rem" }}>{errors.image}</p>}
          <ImageList sx={{ width: 820, height: 170 }} cols={5} rowHeight={164}>
            {productData.image &&
              productData.image.map((item, index) => (
                <ImageListItem key={index} style={{ position: "relative" }}>
                  <img
                    src={item}
                    alt={item}
                    loading="lazy"
                    style={{ objectFit: "cover", height: "100%", width: "100%" }}
                  />
                  <IconButton
                    aria-label="Delete"
                    color="secondary"
                    className="delete-image-button"
                    onClick={() => handleDeleteImage(index)}
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      backgroundColor: "rgba(255, 255, 255, 0.5)",
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ImageListItem>
              ))}
          </ImageList>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handClose}>Hủy</Button>
        <Button onClick={handleSave} variant="contained" color="success">
          {item ? "Cập nhật" : "Thêm"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const NumericFormatCustom = forwardRef(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      prefix="$ "
    />
  );
});

NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ProductDetail;
